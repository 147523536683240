<template>
  <section
    v-if="entity.ended"
    id="dashboard-ecommerce"
  >
    <b-breadcrumb :items="breadcrumbs" />
    <b-row class="match-height mt-1">
      <b-col md="6">
        <statistic-card-with-area-chart
          :statistic="kFormatter(recipients.count)"
          :statistic-title="$t('Recipients')"
          :chart-data="recipients.series"
          icon="UsersIcon"
        />
      </b-col>
      <b-col md="6">
        <statistic-card-with-area-chart
          :statistic="kFormatter(answers.count)"
          :statistic-title="$t('Answers')"
          :chart-data="answers.series"
          icon="MicIcon"
          color="success"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="questionsloaded"
      class="match-height"
    >
      <template v-for="question in questions">
        <b-col
          :key="question.id"
          md="6"
        >
          <QuestionResultsChart
            :data="responses[question.id]"
            :title="question.title"
          />
        </b-col>
      </template>
    </b-row>
  </section>
</template>

<script>
import axios from '@/libs/axios'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import QuestionResultsChart from './QuestionResultsChart.vue'

export default {
  components: {
    StatisticCardWithAreaChart,
    QuestionResultsChart,
  },
  data() {
    return {
      entity: {},
      questionsloaded: false,
      questions: [],
      responses: {},
      recipients: {
        series: [
          {
            name: this.$t('Recipients'),
            data: [28, 40, 36, 52, 38, 60, 55],
          },
        ],
        count: 0,
      },
      answers: {
        series: [
          {
            name: this.$t('Answers'),
            data: [10, 15, 8, 15, 7, 12, 8],
          },
        ],
        count: 0,
      },
    }
  },
  computed: {
    pollId() {
      return this.$route.params.id
    },
    breadcrumbs() {
      return [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' },
        },
        {
          text: this.$t('Votes'),
          to: { name: 'votes-list' },
        },
        {
          text: this.$t('Results'),
          active: true,
        },
      ]
    },
  },
  mounted() {
    this.loadEntity()
    this.updateAllCharts()
  },
  methods: {
    async updateAllCharts() {
      const params = {
        params: {
          poll: this.pollId,
        },
      }
      this.updateRecipientStats(params)
      this.updateAnswerStats(params)
      this.updateQuestions(params)
    },
    async loadEntity() {
      const response = await axios.get(`polls/${this.pollId}`)
      this.entity = response.data
    },
    async updateRecipientStats(params) {
      const response = await axios.get('statistics/polls/recipient-totals', params)
      this.recipients.count = response.data.count
    },
    async updateAnswerStats(params) {
      const response = await axios.get('statistics/polls/answer-totals', params)
      this.answers.count = response.data.count
    },
    async updateQuestions(params) {
      const response = await axios.get('poll-questions/list', params)
      this.questions = response.data
      for (const element of this.questions) {
        await this.updateResponses(element)
      }
      this.questionsloaded = true
    },
    async updateResponses(question) {
      const response = await axios.get('statistics/polls/question-results', {
        params: {
          question: question.id
        }
      })
      const newData = []
      let total = 0
      response.data.forEach((item) => {
        total += item.count
      })
      response.data.forEach((item) => {
        const percent = parseFloat((item.count / total) * 100).toFixed(2)
        newData.push({
          title: item.title,
          count: item.count,
          series: [percent],
        })
      })
      this.responses[question.id] = newData
    },
    kFormatter,
  },
}
</script>